import React, { useEffect, useState } from "react";

const PlaylistComponent = () => {
  const [link, setLink] = useState("");

  const onType = (event) => {
    setLink(event.target.value);
  };

  const onClick = () => {
    console.log(link);
  };

  return (
    <>
      <h1>Playlists</h1>
      <label htmlFor="link">Link</label>
      <br />
      <input type="text" id="link" name="link" onChange={onType} value={link} />
      <br />
      <label htmlFor="button">Button</label>
      <br />
      <button onClick={onClick}>Click Me</button>
    </>
  );
};

export default PlaylistComponent;
