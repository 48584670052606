import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  getUser,
  initAuth,
  logout,
  isLoggedIn,
  handleLogin,
} from "../../services/auth";
import { navigate } from "gatsby";
import Playlist from "../../components/admin/playlist";

const DashboardPage = () => {
  const [widget, setWidget] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const checkLogin = () => {
    const bool = isLoggedIn();
    setLoggedIn(bool);
  };

  const onLog = (f) => {
    return () => {
      if (widget) {
        f((user) => {
          checkLogin();
          navigate("/admin/");
        });
      }
    };
  };

  useEffect(() => {
    if (!widget) {
      setWidget(initAuth());
    }
  }, []);

  useEffect(() => {
    if (widget) {
      checkLogin();
    }
  }, [widget]);

  // Authorized Request
  const onSend = async () => {
    if (widget) {
      try {
        const user = getUser();
        const res = await axios.post(
          "/.netlify/functions/test",
          { message: "We Come in Peace" },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                "Bearer " +
                (user.token != null ? user.token.access_token : "123"),
            },
          }
        );
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <main>
      <h1>xyz</h1>
      <h1>Admin Main page</h1>
      <button onClick={onSend}>Send</button>
      {loggedIn ? (
        <>
          <button onClick={onLog(logout)}>log off</button>
          <h1>Welcome {getUser().user_metadata.full_name}</h1>
          <Playlist></Playlist>
        </>
      ) : (
        <>
          <button onClick={onLog(handleLogin)}>log in</button>
          <h1>Please login to continue</h1>
        </>
      )}
    </main>
  );
};

export default DashboardPage;
